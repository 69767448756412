import React from 'react'
import { navigate } from '@reach/router'

import { Send, PhoneIncoming, MessageCircle, Mail } from 'icons'
import { Text } from 'components'
import { trackingAllowed } from 'tracking'
import loadable from '@loadable/component'

const Newsletter = loadable(() => import('./Newsletter'))

const LIVE_CHAT_ENABLED = false

const routes = [
  {
    icon: Send,
    title: 'Anfrage senden',
    description: 'Nützen Sie unser Kontaktformular.',
    route: '/request',
    eventName: 'contactform_request_start',
    onClick: () => {
      navigate('#anmelden')
    },
  },
  {
    icon: PhoneIncoming,
    title: 'Rückruftermin vereinbaren',
    description: 'Rückruf durch einen Mitarbeiter.',
    eventName: 'contactform_callback_start',
    onClick: () => {
      navigate('#anmeldenCallbackMode')
    },
  },
  LIVE_CHAT_ENABLED &&
    trackingAllowed && {
      icon: MessageCircle,
      title: 'Live Chat',
      description: (
        <Text fontSize={0} color="green">
          ● Jetzt online
        </Text>
      ),
    },
  {
    icon: Mail,
    title: 'Newsletter bestellen',
    description: 'Newsletter jetzt bestellen.',
    route: '/newsletter',
    eventName: 'contactform_newsletter_start',
    component: (props) => <Newsletter {...props} />,
  },
].filter(Boolean)

export { routes }
